
  @media only screen and (min-width: 0px) and (max-width: 1049px) {
    .home{
      width: 100%;
      min-height: 100%;
      padding-bottom: 120px;
      background-color: var(--primaryBackground);
  
      padding-top: 80px;

      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 10px;
    }

    .home_filterpage{

    }

    .home_filterpage_option{      
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height:60px;
      width:100%;
      font-weight:500;
      font-size:18px;
      border-bottom:1px solid #ebebeb;
    }

    .home_filterpage_option_active{      
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height:60px;
      width:100%;
      font-weight:500;
      font-size:18px;
      border-bottom:1px solid #ebebeb;
      background-color: #ffeedb;
      color:#f58700
    }

    .home_productRow_title{
      padding-left:10px;
      font-size:24px;
      font-weight:600;
    }

    .home_productRow{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: auto;

      padding-top:18px;
      padding-bottom:30px;
      flex-wrap: nowrap;
      max-width:100vw;
    }
  }
  
  @media only screen and (min-width: 1050px) {
    .home{
      width: 100%;
      min-height: 100%;
      padding-bottom: 120px;
      background-color: var(--primaryBackground);
  
    }

    .homewrapper{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap:4vw;
      margin-top:24px;
    }

    .homewrapper_left{
      width: 246px;
      padding-left:30px;
    }

    .homewrapper_filterbox{
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap:8px;
      margin-bottom:32px;
    }

    .homewrapper_filterbox_title{
      font-weight:700;
      font-size: 18px;
      letter-spacing: -0.03em;
      padding-bottom:6px;
    }

    .homewrapper_filterbox_option{
      height:45px;
      font-weight:700;
      background-color: #f5f5f7;
      border-radius:8px;
      width: 246px;
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left:16px;
      cursor: pointer;
    }

    .homewrapper_filterbox_option:hover{
      background-color: #ffeedb;
      color:#f58700;
    }

    .homewrapper_filterbox_option_active{
      background-color: #ffeedb;
      color:#f58700;
      height:45px;
      font-weight:700;
      border-radius:8px;
      width: 246px;
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left:16px;
      cursor: pointer;
    }

    .homewrapper_right{
      flex-wrap: wrap;
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap:1.5vw;
    }

    .home_templates{
      font-size: 30px;
      padding-left:10px;
      padding-bottom: 18px;
    }


  }