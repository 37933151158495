@media only screen and (min-width: 0px) and (max-width: 1049px) {
.servicesbar{
  width:100%;
  padding-bottom:24px;

}
.servicesbar_title{
  padding-left:16px;
  font-size: 1.6em;
  font-weight:700;
  padding-bottom:6px;

}

.servicesbar_templates{
  padding-left:16px;
  padding-right:16px;
  padding-bottom:18px;
  font-size: 14px;  display: inline-block;
  color: #717171;
}
.servicesbar_scroll{
  height: 260px;
  padding-left:16px;
  padding-right:16px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  gap:12px;
}

}

@media only screen and (min-width: 1050px) {
  .servicesbar{
    padding-bottom:24px;
  }

  .servicesbar_title{
    padding-left:30px;
    font-size: 2em;
    font-weight:700;
    padding-bottom:20px;
  }

  .servicesbar_scroll{
    height: 350px;
    padding-left:30px;
    padding-right:30px;
  
    display: flex;
    flex-direction: row;
    gap:24px;
  }
}