/* Skalierungsfaktoren */

/* Mobil/Tablet */
@media only screen and (max-width: 1049px) {
  .bottomnavbar {
    position: fixed;
    bottom: 0;
    height: calc(57px + env(safe-area-inset-bottom));
    padding: env(safe-area-inset);
    width: 100%;
    background-color: var(--white);
    z-index: 9;

    border-top: 1px solid #ededed;
  }

  .bottomnavbar_buyer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .bottomnavbar_buyer_navpoint {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--xxsFontSize);
    color: var(--lfthirdColor);
  }

  .bottomnavbar_seller {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .bottomnavbar_seller_navpoint {
    width: calc(100% / 5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--xxsFontSize);
    color: var(--lfthirdColor);
  }

  .bottomnavbar_seller_navpoint_active {
    width: calc(100% / 5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--xxsFontSize);
    color: var(--lfmainColor);
  }

  .bottomnavbar_buyer_navpoint_active {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--xxsFontSize);
    color: var(--lfthirdColor);
  }


  .bottomnavbar_seller_navpoint_incomeicon {
    font-size: 23px;
    color: #b0b0b0;
    margin-top: 4px;
  }

  .bottomnavbar_seller_navpoint_incomeicon_active {
    font-size: 23px;
    color: #f58700;
    margin-top: 4px;
  }

  .bottomnavbar_creatorpage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
    background-color: #125fc9;
    color: white;
    height: 48px;
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 4px;
  }

  .bottomnavbar_creatorpage_empty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
    border: 1px solid #125fc9;
    color: #125fc9;
    height: 48px;
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 4px;
  }

  .bottomnavbar_creatorpage_left {
  }

  .bottomnavbar_creatorpage_left_toptext {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bottomnavbar_creatorpage_left_bottomtext {
    font-weight: 300;
  }

  .bottomnavbar_creatorpage_right {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    font-weight: 500;
  }

  .bottomnavbar_creatorpage_right_empty {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    color: grey;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    font-weight: 500;
  }

  .bottomnavbar_creatorpage_left_toptext_count {
    background: #f58700;
    border-radius: 99px;
    width: 18px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    transform: translate(-6px, -8px);
  }
}

/* Desktop*/
@media only screen and (min-width: 1050px) {
.bottomnavbar {
  position: fixed;
  bottom: 0;
  height: calc(57px + env(safe-area-inset-bottom));
  padding: env(safe-area-inset);
  width: 100%;
  background-color: var(--white);
  z-index: 9;
  max-width: 985px !important;

  border-top: 1px solid #ededed;
}

.bottomnavbar_buyer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.bottomnavbar_buyer_navpoint {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--xxsFontSize);
  color: var(--lfthirdColor);
}

.bottomnavbar_seller {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.bottomnavbar_seller_navpoint {
  width: calc(100% / 4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--xxsFontSize);
  color: var(--lfthirdColor);
}

.bottomnavbar_seller_navpoint_active {
  width: calc(100% / 4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--xxsFontSize);
  color: var(--lfmainColor);
}

.bottomnavbar_buyer_navpoint_active {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--xxsFontSize);
  color: var(--lfthirdColor);
}
.bottomnavbar_buyer_navpoint_active {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--xxsFontSize);
  color: var(--lfmainColor);
}

.bottomnavbar_seller_navpoint_incomeicon {
  font-size: 23px;
  color: #b0b0b0;
  margin-top: 4px;
}

.bottomnavbar_seller_navpoint_incomeicon_active {
  font-size: 23px;
  color: #f58700;
  margin-top: 4px;
}

.bottomnavbar_creatorpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: calc(100% - 32px);
  background-color: #125fc9;
  color: white;
  height: 48px;
  margin-left: 16px;
  border-radius: 12px;
  margin-top: 4px;
}

.bottomnavbar_creatorpage_empty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: calc(100% - 32px);
  border: 1px solid #125fc9;
  color: #125fc9;
  height: 48px;
  margin-left: 16px;
  border-radius: 12px;
  margin-top: 4px;
}

.bottomnavbar_creatorpage_left {
}

.bottomnavbar_creatorpage_left_toptext {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottomnavbar_creatorpage_left_bottomtext {
  font-weight: 300;
}

.bottomnavbar_creatorpage_right {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  font-weight: 500;
}

.bottomnavbar_creatorpage_right_empty {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  color: grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  font-weight: 500;
}

.bottomnavbar_creatorpage_left_toptext_count {
  background: #f58700;
  border-radius: 99px;
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transform: translate(-6px, -8px);
}
}