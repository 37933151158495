@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .service {
  }

  .service_flexwrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    gap: 2%;
    width: 100%;
  }

  .service_closeBtn {
    width: 60px;
    height: 60px;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_closeBtn:hover {
    background-color: lightgrey;
  }

  .service_preview {
    width: 100vw;
    height: calc(100vh - constant(safe-area-inset-bottom));
  }

  .service_preview_topbar {
    position: absolute;
    padding-top: 18px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.217),
      rgba(0, 0, 0, 0)
    );
    width: 100vw;
  }

  .service_preview_topbar_progressbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    gap: 5px;
  }

  .service_preview_topbar_progressbar_bar {
    background-color: rgba(255, 255, 255, 0.373);
    height: 5px;
    border-radius: 999px;
    margin-bottom: 10px;
    width: 100%;
  }

  .service_preview_topbar_progressbar_bar_progress {
    background-color: white;
    height: 5px;
    width: 0px;
    border-radius: 999px;
  }

  .service_preview_topbar_bottombar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .service_preview_topbar_text {
    color: white;
    width: calc(100% - 100px);
  }

  .service_preview_topbar_text_top {
  }

  .service_preview_topbar_text_bottom {
    font-size: 18px;
    font-weight: 500;
  }

  .service_preview_topbar_img {
    width: 50px;
    height: 50px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_preview_topbar_toptext {
  }

  .service_preview_topbar_bottomtext {
  }

  .service_preview_mediaplayerWrapper {
    height: 100%;
  }

  .service_preview_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .service_preview_bottombar {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-top: -130px;
  }

  .service_preview_bottombar_playbutton {
    height: 70px;
    width: 70px;
    background-color: #f3f3f3;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    box-shadow: var(--basicBoxShadow);
    cursor: pointer;
  }

  .service_preview_bottombar_playbutton:hover {
    background-color: #f58700;
  }

  .service_details {
    width: calc(100% - 60px - 400px);
  }

  .service_details_topbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .service_details_title {
    font-size: 36px;
    font-weight: 700;
  }

  .service_details_savebutton {
    padding-left: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 24px;
    background-color: #f58700;
    border-radius: 12px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }

  .service_details_savebutton:hover {
    background-color: #ad5f00;
  }

  .service_overviewbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 24px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .service_overviewbar_box {
  }

  .service_overviewbar_box_title {
    font-size: 24px;
    font-weight: 700;
    color: #545454;
    margin-bottom: 8px;
    margin-top: 18px;
  }

  .service_overviewbar_box_element {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .service_overviewbar_box_element_left {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .service_overviewbar_box_element_left_img {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    object-fit: cover;
  }

  .service_overviewbar_box_element_right {
    font-size: 30px;
    font-weight: 700;
  }

  .service_pickCreator {
  }

  .service_pickCreator_topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  .service_pickCreator_topBar_title {
    font-size: 24px;
    font-weight: 500;
  }

  .service_pickCreator_topBar_Save {
    width: 220px;
    height: 50px;
    font-weight: 500;
    background-color: #f3f3f3;
    color: #f58700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
  }

  /*
  gebotsverfahren der werbung.
  wenn eine werbung 
  
  we provide templates of how to create branded content easily,
  so that creators and smb dont need to meet or chat.
    */

  .service_pickCreator_topBar_Save:hover {
    background-color: lightgrey;
  }

  .service_pickCreator_creators {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }

  .service_pickCreator_creators_creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 160px;
    background-color: #f3f3f3;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_pickCreator_creators_creator_active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 160px;
    background-color: #ffeedb;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_pickCreator_creators_creator:hover {
    background-color: #e7e7e7;
  }

  .service_pickCreator_creators_creator_top {
  }

  .service_pickCreator_creators_creator_top_img {
    width: 75px;
    height: 75px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_pickCreator_creators_creator_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .service_details_rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .service_details_rows_row {
    width: 100%;
    height: 80px;
    background-color: #f3f3f3;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    cursor: pointer;
  }

  .service_details_rows_row:hover {
    background-color: lightgrey;
  }

  .service_details_rows_row_left {
    padding-right: 20px;
  }

  .service_details_rows_row_left_img {
    width: 63px;
    height: 63px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_details_rows_row_middle {
    width: calc(100% - 90px - 70px);
  }

  .service_details_rows_row_middle_top {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .service_details_rows_row_middle_bottom {
  }

  .service_details_rows_row_right {
  }
}

@media only screen and (min-width: 1050px) {
  .service {
  }

  .service_flexwrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    gap: 2%;
    width: 100%;
  }

  .service_closeBtn {
    width: 60px;
    height: 60px;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_closeBtn:hover {
    background-color: lightgrey;
  }

  .service_preview {
    width: 400px;
    height: 600px;
  }

  .service_preview_topbar {
    position: absolute;
    padding-top: 18px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.217),
      rgba(0, 0, 0, 0)
    );
    width: 384px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .service_preview_topbar_progressbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    gap: 5px;
  }

  .service_preview_topbar_progressbar_bar {
    background-color: rgba(255, 255, 255, 0.373);
    height: 5px;
    border-radius: 999px;
    margin-bottom: 10px;
    width: 100%;
  }

  .service_preview_topbar_progressbar_bar_progress {
    background-color: white;
    height: 5px;
    width: 0px;
    border-radius: 999px;
  }

  .service_preview_topbar_bottombar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  .service_preview_topbar_text {
    color: white;
  }

  .service_preview_topbar_text_top {
  }

  .service_preview_topbar_text_bottom {
    font-size: 18px;
    font-weight: 500;
  }

  .service_preview_topbar_img {
    width: 50px;
    height: 50px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_preview_topbar_toptext {
  }

  .service_preview_topbar_bottomtext {
  }

  .service_preview_mediaplayerWrapper {
    height: 100%;
  }

  .service_preview_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .service_preview_bottombar {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 380px;
    margin-top: -90px;
  }

  .service_preview_bottombar_playbutton {
    height: 70px;
    width: 70px;
    background-color: #f3f3f3;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    box-shadow: var(--basicBoxShadow);
    cursor: pointer;
  }

  .service_preview_bottombar_playbutton:hover {
    background-color: #f58700;
  }

  .service_details {
    width: calc(100% - 60px - 400px);
  }

  .service_details_topbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .service_details_title {
    font-size: 36px;
    font-weight: 700;
  }

  .service_details_savebutton {
    padding-left: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 24px;
    background-color: #f58700;
    border-radius: 12px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }

  .service_details_savebutton:hover {
    background-color: #ad5f00;
  }

  .service_overviewbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 24px;
    padding-right: 100px;
    padding-top: 8px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .service_overviewbar_box {
  }

  .service_overviewbar_box_title {
    font-size: 24px;
    font-weight: 700;
    color: #545454;
    margin-bottom: 8px;
    margin-top: 18px;
  }

  .service_overviewbar_box_element {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .service_overviewbar_box_element_left {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .service_overviewbar_box_element_left_img {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    object-fit: cover;
  }

  .service_overviewbar_box_element_right {
    font-size: 30px;
    font-weight: 700;
  }

  .service_pickCreator {
  }

  .service_pickCreator_topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  .service_pickCreator_topBar_title {
    font-size: 24px;
    font-weight: 500;
  }

  .service_pickCreator_topBar_Save {
    width: 220px;
    height: 50px;
    font-weight: 500;
    background-color: #f3f3f3;
    color: #f58700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
  }

  /*
gebotsverfahren der werbung.
wenn eine werbung 

we provide templates of how to create branded content easily,
so that creators and smb dont need to meet or chat.
  */

  .service_pickCreator_topBar_Save:hover {
    background-color: lightgrey;
  }

  .service_pickCreator_creators {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }

  .service_pickCreator_creators_creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 160px;
    background-color: #f3f3f3;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_pickCreator_creators_creator_active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 160px;
    background-color: #ffeedb;
    border-radius: 12px;
    cursor: pointer;
  }

  .service_pickCreator_creators_creator:hover {
    background-color: #e7e7e7;
  }

  .service_pickCreator_creators_creator_top {
  }

  .service_pickCreator_creators_creator_top_img {
    width: 75px;
    height: 75px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_pickCreator_creators_creator_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .service_details_rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .service_details_rows_row {
    width: 100%;
    height: 80px;
    background-color: #f3f3f3;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    cursor: pointer;
  }

  .service_details_rows_row:hover {
    background-color: lightgrey;
  }

  .service_details_rows_row_left {
    padding-right: 20px;
  }

  .service_details_rows_row_left_img {
    width: 63px;
    height: 63px;
    border-radius: 999px;
    object-fit: cover;
  }

  .service_details_rows_row_middle {
    width: calc(100% - 90px - 70px);
  }

  .service_details_rows_row_middle_top {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .service_details_rows_row_middle_bottom {
  }

  .service_details_rows_row_right {
  }

  .service_toprow_title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
