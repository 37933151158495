
  @media only screen and (min-width: 0px) and (max-width: 1049px) {
    .creator {
      /* Your styles for the outer container */
    }

    .creator_wrapper{
display:flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width:100%;
    }

    .close_btn{
      width:50px;
      height:50px;
      background-color: #f3f3f3;
      border-radius:9px;
      display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
cursor: pointer;

position:absolute;
margin-left:10px;
margin-top:10px;
    }

    .close_btn:hover{
      background-color: #d7d7d7;
    }

    .creator_left{
      width:100%;
    }
    
    .creator_left_imgbox{

    }

    .creator_left_imgbox_img{
      width: 100%;
      height:auto;
   }

      .creator_left_moreimages{
        width:100%;
        height:80px;
        padding-top:10px;
        padding-bottom:10px;
        padding-left:12px;
        padding-right:12px;
      }

      .creator_left_moreimages_img{
        width:60px;
        height:60px;
        margin-right:6px;
        border-radius: 4px;
        border:2px solid #ffffff;
        object-fit: cover;
        
      }

      .creator_left_moreimages_img_active{
        width:60px;
        height:60px;
        margin-right:6px;
        border-radius: 4px;
        border:2px solid #f58700;
        object-fit: cover;
      }

    .creator_left_descriptionbox{
      padding-left:12px;
      padding-right:12px;

    }

    .creator_left_descriptionbox_name{
      font-weight:500;
      font-size:26px;
    }

    .creator_left_descriptionbox_follower{
      font-size:18px;
      font-weight: 500;
      color: #f58700;
    }

    .creator_left_openInstaProfile{
      height:50px;
      width:calc(100% - 24px);
      margin-left:12px;
      background-color: #f3f3f3;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      gap:10px;
      padding-left:10px;
      padding-right:10px;
      border-radius:8px;
      margin-top:18px;
      cursor: pointer;
    }

    .creator_left_openInstaProfile:hover{
      background-color: #dddddd;
    }

    .creator_instagram_iframe{
      border: none;
      overflow: hidden;
      height: 500px;
      width:100%;
    }

    .creator_right{
      width:calc(100%);
      border-top:1px solid #dddddd;
      margin-top:18px;
    }

    .creator_right_title{
      font-size: 24px;
      font-weight:500;
      margin-bottom: 18px;
      margin-top: 18px;
      padding-left:12px;
    }

    .creator_right_servicelist{

    }

    .creator_right_servicelist_service{
      display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid #ebebeb;
padding-bottom:12px;
padding-top:12px;
padding-left:8px;
padding-right:12px;
cursor:pointer;
    }

    .creator_right_servicelist_service_active{
   
      border-bottom: 1px solid #ebebeb;
      padding-bottom:12px;
      padding-top:12px;
      padding-left:8px;
      padding-right:12px;
      cursor:pointer;
      background-color: #ffeedb;

    }

    .creator_right_servicelist_service_active_top{
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .creator_right_servicelist_service_active_bottom{
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap:8px;
      flex-wrap: wrap;
    }

    .creator_right_servicelist_service_active_bottom_element{
      padding:10px;      
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      box-shadow: var(--basicBoxShadow);
      background-color: white;
      border-radius: 8px;
      gap:6px;
      width:100%;
    }

    .creator_right_servicelist_service_active_bottom_element_img{
      width:50px;
      height:50px;
    }

    .creator_right_servicelist_service:hover{
      background-color: #f3f3f3;
      
    }

    .creator_right_servicelist_service_left{
      display:flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
    }

    .creator_right_servicelist_service_left_img{
      width:48px;
      height:48px;
      border-radius:8px;
      object-fit: cover;
    }

    .creator_right_servicelist_service_left_text{
      padding-left:12px;
    }

    .creator_right_servicelist_service_left_text_top{
      font-weight:500;
      font-size:18px;
    }

    .creator_right_servicelist_service_left_text_bottom{
      color: #222222;
    }

    .creator_right_servicelist_service_right{

    }

    .creator_right_servicelist_service_right_addButton{
      background-color: #f58700;
      color:white;
      height:40px;
      width:150px;
      display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
border-radius:8px;
cursor: pointer;
    }

    .creator_right_servicelist_service_right_addButton:hover{
      background-color: #b26200;

    }
  }
  
  @media only screen and (min-width: 1050px) {
    .creator {
      /* Your styles for the outer container */
    }

    .creator_wrapper{
display:flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
width:100%;
padding-left:30px;
padding-right:30px;
    }

    .close_btn{
      width:50px;
      height:50px;
      background-color: #f3f3f3;
      border-radius:9px;
      display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
cursor: pointer;
    }

    .close_btn:hover{
      background-color: #d7d7d7;
    }

    .creator_left{
      width:30%;
      padding-left:2%;
      padding-right: 2%;
    }
    
    .creator_left_imgbox{

    }

    .creator_left_imgbox_img{
      width: 100%;
      height:auto;
      border-radius:8px;    }

      .creator_left_moreimages{
        width:100%;
        height:80px;
        padding-top:10px;
        padding-bottom:10px;

      }

      .creator_left_moreimages_img{
        width:60px;
        height:60px;
        margin-right:6px;
        border-radius: 4px;
        border:2px solid #ffffff;
        object-fit: cover;
      }

      .creator_left_moreimages_img_active{
        width:60px;
        height:60px;
        margin-right:6px;
        border-radius: 4px;
        border:2px solid #f58700;
        object-fit: cover;
      }

    .creator_left_descriptionbox{

    }

    .creator_left_descriptionbox_name{
      font-weight:500;
      font-size:26px;
    }

    .creator_left_descriptionbox_follower{
      font-size:18px;
      font-weight: 500;
      color: #f58700;
    }

    .creator_left_openInstaProfile{
      height:50px;
      width:100%;
      background-color: #f3f3f3;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      gap:10px;
      padding-left:10px;
      padding-right:10px;
      border-radius:8px;
      margin-top:18px;
      cursor: pointer;
    }

    .creator_left_openInstaProfile:hover{
      background-color: #dddddd;
    }

    .creator_instagram_iframe{
      border: none;
      overflow: hidden;
      height: 500px;
      width:100%;
    }

    .creator_right{
      width:calc(100% - 30%)

    }

    .creator_right_title{
      font-size: 33px;
      font-weight:500;
      margin-bottom: 18px;
    }

    .creator_right_servicelist{

    }

    .creator_right_servicelist_service{
      display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid #ebebeb;
padding-bottom:12px;
padding-top:12px;
padding-left:8px;
padding-right:12px;
cursor:pointer;
    }

    .creator_right_servicelist_service_active{
   
      border-bottom: 1px solid #ebebeb;
      padding-bottom:12px;
      padding-top:12px;
      padding-left:8px;
      padding-right:12px;
      cursor:pointer;
      background-color: #ffeedb;

    }

    .creator_right_servicelist_service_active_top{
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .creator_right_servicelist_service_active_bottom{
      display:flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap:8px;
      flex-wrap: wrap;
    }

    .creator_right_servicelist_service_active_bottom_element{
      padding:10px;      
      display:flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: var(--basicBoxShadow);
      background-color: white;
      border-radius: 8px;
      gap:6px;
      width:calc(33% - 4px);

    }

    .creator_right_servicelist_service_active_bottom_element_img{
      width:100%;
      height:auto;
    }

    .creator_right_servicelist_service:hover{
      background-color: #f3f3f3;
      
    }

    .creator_right_servicelist_service_left{
      display:flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
    }

    .creator_right_servicelist_service_left_img{
      width:48px;
      height:48px;
      border-radius:8px;
      object-fit: cover;
    }

    .creator_right_servicelist_service_left_text{
      padding-left:12px;
    }

    .creator_right_servicelist_service_left_text_top{
      font-weight:500;
      font-size:18px;
    }

    .creator_right_servicelist_service_left_text_bottom{
      color: #222222;
    }

    .creator_right_servicelist_service_right{

    }

    .creator_right_servicelist_service_right_addButton{
      background-color: #f58700;
      color:white;
      height:40px;
      width:150px;
      display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
border-radius:8px;
cursor: pointer;
    }

    .creator_right_servicelist_service_right_addButton:hover{
      background-color: #b26200;

    }
  }