@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .cart {
  }
}

@media only screen and (min-width: 1050px) {
  .cart {
    padding-left: 90px;
    padding-right: 90px;
  }

  .cart_title {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .cart_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .cart_list_titlerow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
  }

  .cart_list_titlerow_column {
    width: calc(100% / 3);
  }

  .cart_list_element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    border-bottom: 1px solid #ebebeb;
  }

  .cart_list_element:hover {
    background-color: #f3f3f3;
  }

  .cart_list_element_column {
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .cart_list_element_column_imagebox {
    width: 80px;
    height: 80px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .cart_list_element_column_imagebox_img {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    object-fit: cover;
  }

  .border-round {
    border-radius: 999px;
  }

  .cart_list_element_column_text {
  }

  .cart_list_element_column_btn {
    width: 220px;
    height: 45px;
    background-color: #f58700;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;

    margin-left: 24px;
  }

  .cart_list_element_column_btn:hover {
    background-color: #be6800;
  }

  .cart_list_element_column_inCreation{
    width: 220px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #0000b4;
    font-weight: 500;
    margin-left: 24px;
  }

  .cart_list_element_column_Ready{
    width: 220px;
    height: 45px;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 24px;
    color:black;
    font-weight: 500;

    border: 1px solid #f58700;
  }

  .cart_list_element_column_Ready:hover{
    background-color: #dddddd;
  }
}
