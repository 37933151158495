
  @media only screen and (min-width: 0px) and (max-width: 1049px) {
    .createService{
      width: 100%;
      min-height: 100%;
      padding-bottom: 60px;
      background-color: var(--primaryBackground);
  
    }
    

    .createService_inputBtn{

    }

    .createService_inputBtn_img{
      width:100%;
      height:250px;
    }

    .createService_inputlabel{
      width: 100%;
      height:250px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top:1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }

    .createService_publishchannel{

    }

    .createService_publishchannel_box{
      width:calc(100% - 32px);
      margin-left: 16px;
      border: 1px solid #dddddd;
      border-radius:12px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top:12px;
    }

    .createService_publishchannel_box_oneElement{
      height:48px;
      width:100%;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;          
      border-radius:12px;
    }

    .createService_publishchannel_box_element{
      height:48px;
      width:50%;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border:1px solid #dddddd;
    }

    .createService_price{
      width:calc(100% - 32px);
      margin-left: 16px;
    }

    .createService_CTA{
      width:calc(100% - 32px);
      margin-left: 16px;
      height:48px;
      background-color: #125fc9;
      border-radius:12px;
      color:white;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media only screen and (min-width: 1050px) {
    .createService{
      width: 100%;
      min-height: 100%;
      padding-bottom: 120px;
      background-color: var(--primaryBackground);
  
      padding-top: 174px;
    }
  }