
  @media only screen and (min-width: 0px) and (max-width: 1049px) {
    .checkout{
      width: 100%;
      min-height: 100%;
      padding-bottom: 120px;
      background-color: var(--primaryBackground);
  
    }

    .checkout_columns{

      width:100%;
      padding-left:16px;
      padding-right:16px;
    }

    .checkout_columns_left{
    }

    .checkout_columns_right{
    }
    .checkout_columns_left_inputs_row{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap:18px;
    }

      .checkout_row{
        width:100%;
        padding-left:16px;
        padding-right:16px;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    .checkout_cta{
      width:100%;
      height:48px;
    background-color: #f58700;
    border-radius:12px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:white;
    }
  }
  
  @media only screen and (min-width: 1050px) {
    .checkout{
      width: 100%;
      min-height: 100%;
      padding-bottom: 120px;
      background-color: var(--primaryBackground);
  
      line-height: 1.5em;
    }

    .checkout_wrapper{
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }

    .checkout_left{
      width: 100%;
    }

    .checkout_left_left{
      width: 100%;
    }

    .checkout_columns{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width:100%;
      padding-left:6%;
      padding-right:6%;
      gap:18px;
    }

    .checkout_columns_left{
      width:100%;
    }

    .checkout_columns_left_inputs{
      width: 100%;
    }

    .checkout_columns_right{
      width:100%;
    }
    .checkout_columns_left_inputs_row{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap:18px;
      width: 100%;
    }

      .checkout_row{
        width:100%;
        padding-left:6%;
        padding-right:6%;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

    .checkout_right{
      padding-right: 30px;
    }

    .checkout_right_title{
      font-size:22px;
      font-weight: 500;
      margin-bottom:24px;
    }

    .checkout_right_ad{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .checkout_right_ad_imagebox{

    }

    .checkout_right_ad_imagebox_img{
      width:50px;
      height:50px;
      border-radius: 4px;
    }

    .checkout_right_ad_name{
padding-left: 12px;
font-weight:500;

    }

    .checkout_right_creator{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .checkout_right_creator_imagebox{

    }

    .checkout_right_creator_imagebox_img{
      width:50px;
      height:50px;
      border-radius: 4px;
    }

    .checkout_right_creator_name{
      padding-left: 12px;

      font-weight:500;
    }

    .checkout_right_pay{
    
    }

    .checkout_right_pay_price{
      text-align:left;
      font-size: 22px;
      margin-bottom: 12px;
      margin-top:18px;
    }

    .checkout_cta{
      min-width:200px;
      width: 15vw;
      height:48px;
    background-color: #f58700;
    border-radius:12px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:white;
    cursor: pointer;
    }

    .checkout_cta:hover{
      background-color: #b86500;
    }
  }