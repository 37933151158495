@media only screen and (min-width: 0px) and (max-width: 1049px) {

  .defaulttopnav{
    height:60px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .defaulttopnav_backHitBox{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height:100%;
    padding-left:10px;
    gap: 8px;
  }

  .defaulttopnav_backHitBox_backButton{
    height: 40px;
    width:40px;
    background-color: #f3f3f3;
    border-radius: 4px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .topnavbar {
    /* Stil für die gesamte obere Navigationsleiste */
    position: fixed;
    top: 0;
    height: 63px;
    width: 100%;
    background-color: var(--white);
    z-index: 9;
    padding-left:14px;
    padding-right:14px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  

 .topnavbar_left{
  background-color: #f3f3f3;
  height:48px;
  width:calc(100% - 33px - 33px - 8px - 8px - 8px);
  border-radius:999px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
 }

 .topnavbar_left_left{
  padding-left:24px;
 }
 

 .topnavbar_left_left_toptext{
  color: #f58700;
  font-size:12px;
  font-weight:600;
 }

 .topnavbar_left_left_bottomtext{
  color: #000000;
  font-weight:600;
font-size:18px;
 }

 .topnavbar_left_right{
  width:44px;
  height:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom:4px;
  padding-right:22px;
 }

 .topnavbar_right{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:8px;
 }

 .topnavbar_right_iconbox{
  width:33px;
  height:33px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 }

}
@media only screen and (min-width: 1050px) {

.padding-left{
  padding-left:30px;
}

  .defaulttopnav{
    padding-left: 30px;
    padding-right: 30px;
  }

  .topnavbar {
    /* Stil für die gesamte obere Navigationsleiste */
    top: 0;
    height: 150px;
    width: 100%;
    background-color: var(--white);
    z-index: 9;
    padding-left:30px;
    padding-right:30px;
  }


  .topnavbar_top{
    width: 100%;
    height:61px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .topnavbar_top_left{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:12px;
  }

  .topnavbar_top_left_logo{
    width: 160px;
    font-size: 24px;
    font-weight:700;
    letter-spacing: -0.05em;
    color:#f58700;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:7px;
  }

  .topnavbar_top_left_language{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height:32px;
    font-size:14px;
    font-weight: 500;
    color: #717171;
    height:48px;
    padding-left:12px;
    padding-right:12px;
    gap:4px;
    border-radius:999px;
    cursor: pointer;
  }

  .topnavbar_top_left_language:hover{
    background-color: #f3f3f3;
  }

  .topnavbar_top_searchbar{
  width: 25vw;
  border-radius:999px;
  height:48px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding-left: 18px;
  padding-right: 24px;
  gap:8px;
  }

  .topnavbar_top_searchbar:hover{
    border:2px solid black;
    padding-left: 16px;
    padding-right: 22px;
  }

  .topnavbar_top_searchbar_input{
    width:100%;
    height:100%;
    border:none;
    background-color: #f3f3f3;
font-size:1em;
  }

  .topnavbar_top_navbar{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:12px;
  }

  .topnavbar_top_navbar_navElement{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height:48px;
    padding-left:12px;
    padding-right:12px;
    gap:4px;
    border-radius:999px;
    color: #717171;
  }

  .topnavbar_top_navbar_navElement:hover{
  background-color: #f3f3f3;
  cursor: pointer;
  }


  .topnavbar_bottom{
    height: 78px;
    width:100%;
  }

  .topnavbar_bottom_scroll{
    overflow-x: scroll;
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:12px;
  }

  .topnavbar_bottom_scroll_iconBox{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #ffffff;
    background-color: #f5f5f7;
gap:5px;
width: 98px;
height:75px;
border-radius:8px;
font-weight:500;

  }

  .topnavbar_bottom_scroll_iconBox:hover{
    color: #f58700;
    background-color: #ffeedb;
    cursor: pointer;
  }

  .topnavbar_bottom_scroll_iconBox_active{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
gap:5px;
    color: #f58700;
    background-color: #ffeedb;
    width: 98px;
height:75px;
border-radius:8px;
font-weight:500;
  }

}
