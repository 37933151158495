
  @media only screen and (min-width: 0px) and (max-width: 1049px) {
    .textinput{
      width: 100%;
      margin-bottom:18px;

    }

    .textinput_title{
      font-size:0.8em;
      font-weight: 600;
      padding-bottom:4px;
    }

    .textinput_input{
      border-radius: 4px;
      border: 1px solid #dddddd;
      height: 40px;
      padding-left: 12px;
      width:100%;
    }

    .textinput_large{
      width: 100%;
      margin-bottom:18px;
      height:200px;
      padding-left:16px;
      padding-right:16px;
      padding-top:16px;
    }

    .textinput_large_title{
      font-size:1.3em;
      font-weight: 600;
      padding-bottom:12px;
    }

    .textinput_large_input{
      border-radius: 4px;
      border: 1px solid #dddddd;
      height:200px;
      padding-left: 12px;
      width:100%;
    }
  }
  
  @media only screen and (min-width: 1050px) {
    .textinput{
      width: 100%;
      margin-bottom:18px;
    }

    .textinput_title{
      font-size:0.8em;
      font-weight: 600;
      padding-bottom:4px;
    }

    .textinput_input{
      border-radius: 4px;
      border: 1px solid #dddddd;
      height: 40px;
      padding-left: 12px;
      width:100%;
    }

    .textinput_large{
      width: 100%;
      margin-bottom:18px;
      height:200px;
    }

    .textinput_large_title{
      font-size:1.3em;
      font-weight: 600;
      padding-bottom:12px;
    }

    .textinput_large_input{
      border-radius: 4px;
      border: 1px solid #dddddd;
      height:200px;
      padding-left: 12px;
      width:100%;
    }
  }