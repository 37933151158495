@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .card {
    min-width: calc(45vw - 10px);
    height: 333px;
    margin-left:5px;
  }

  .card_top {
    max-width: 100%;
    max-height: calc(100% - 80px);
  }

  .card_top_imagebox {
    border-radius: 8px;
    max-height: 100%;
  }

  .card_top_imagebox_img {
    width: 100%;
    height: 220px;
    border-radius: 8px;
    max-height: 100%;
    object-fit: cover;
  }

  .card_bottom {
  }

  .card_bottom_top {
    padding-top: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
  }

  .card_bottom_top_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }

  .card_bottom_top_name_top {
  }

  .card_bottom_top_name_bottom {
    font-size: 0.8em;
  }

  .card_bottom_top_name_image {
    width: 33px;
    height: 33px;
    border-radius: 99px;
  }

  .card_bottom_top_rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .card_bottom_top_rating_top {
    font-weight: 500;
  }

  .card_bottom_top_rating_bottom {
    font-size: 0.8em;
  }

  .card_bottom_middle {
    color: #717171;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  .card_bottom_bottom {
    font-weight: 500;
  }
}

@media only screen and (min-width: 1050px) {
  .card {
    height: 333px;
    width: 240px;
    padding: 10px;
    cursor: pointer;
  }

  .card:hover {
    background-color: #f3f3f3;
    border-radius: 8px;
  }

  .card_top {
    width: 220px;
    height: 220px;
  }

  .card_top_imagebox {
    border-radius: 8x;
    max-height: 100%;
    width: 220px;
    height: 220px;
  }

  .card_top_imagebox_img {
    width: 220px;
    height: 220px;
    border-radius: 8px;
    object-fit: cover;
  }

  .card_bottom {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-start;
  }

  .card_bottom_top {
    padding-top: 8px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
  }

  .card_bottom_top_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }

  .card_bottom_top_name_top {
  }

  .card_bottom_top_name_bottom {
  }

  .card_bottom_top_name_image {
    width: 33px;
    height: 33px;
    border-radius: 99px;
  }

  .card_bottom_top_rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .card_bottom_top_rating_top {
    font-weight: 500;
  }

  .card_bottom_top_rating_bottom {
    font-size: 0.8em;
  }

  .card_bottom_middle {
    color: #717171;
    padding-bottom: 6px;
  }

  .card_bottom_bottom {
    font-size: 18px;
    font-weight: 500;
  }
}
