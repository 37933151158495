/* Skalierungsfaktoren */

/* Mobil/Tablet */
@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .login {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);
  }

.login_registerform{

}

.login_registerform_title{
font-weight: 500;
font-size: 24px;
padding-left: 16px;
padding-top: 32px;
padding-bottom: 8px;
}


.login_input{
border: 2px solid #222222;
height: 48px;
width: calc( 100% - 32px);
margin-left: 16px;
border-radius:12px;
margin-top: 12px;
padding-left: 12px;
font-size: 1em;

}

.login_ctabtn{
  height: 48px;
  width: calc( 100% - 32px);
  margin-left: 16px;
  border-radius:12px;
  margin-top: 12px;
background-color: var(--lfmainColor);
display: flex;
align-items: center;
flex-direction: row;
justify-content: center;
color: white;
}

.login_ctabtn_disabled{
  height: 48px;
  width: calc( 100% - 32px);
  margin-left: 16px;
  border-radius:12px;
  margin-top: 12px;
display: flex;
align-items: center;
flex-direction: row;
justify-content: center;
color: #f58700;
border: 1px solid #f58700;
}

.login_divider{
  width: calc( 100% - 32px);
  margin-left: 16px;
  display: flex;
align-items: center;
flex-direction: row;
justify-content: center;
height: 48px;
}

.login_sociallogin{
  height: 48px;
  width: calc( 100% - 32px);
  margin-left: 16px;
  border-radius:12px;
  margin-top: 12px;
border: 1px solid #dddddd;
display: flex;
align-items: center;
flex-direction: row;
justify-content: center;
}

}
/* Desktop*/
@media only screen and (min-width: 1050px) {
  .login {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);
  }

  .login_registerform{

  }
  
  .login_registerform_title{
  font-weight: 500;
  font-size: 24px;
  padding-left: 16px;
  padding-top: 32px;
  padding-bottom: 8px;
  }
  
  
  .login_input{
  border: 2px solid #222222;
  height: 48px;
  width: calc( 100% - 32px);
  margin-left: 16px;
  border-radius:12px;
  margin-top: 12px;
  padding-left: 12px;
  font-size: 1em;
  
  }
  
  .login_ctabtn{
    height: 48px;
    width: calc( 100% - 32px);
    margin-left: 16px;
    border-radius:12px;
    margin-top: 12px;
  background-color: var(--lfmainColor);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: white;
  }
  
  .login_ctabtn_disabled{
    height: 48px;
    width: calc( 100% - 32px);
    margin-left: 16px;
    border-radius:12px;
    margin-top: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #f58700;
  border: 1px solid #f58700;
  }
  
  .login_divider{
    width: calc( 100% - 32px);
    margin-left: 16px;
    display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 48px;
  }
  
  .login_sociallogin{
    height: 48px;
    width: calc( 100% - 32px);
    margin-left: 16px;
    border-radius:12px;
    margin-top: 12px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  }}