@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .profile {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);

  }

  .profile_topnav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    height:60px;
  }

  .profile_topnav_left {
  }

  .profile_topnav_right {
  }

  .profile_details {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .profile_details_imgBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .profile_details_imgBox_img {
    width: 33vw;
    height: 33vw;
    border-radius: 999px;
  }

  .profile_details_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .profile_details_description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .profile_details_channels {
  }

  .profile_services {
  }

  .profile_services_box {
  }

  .profile_services_box_title {
    padding-left: 16px;
    padding-right: 16px;
  }

  .profile_services_box_scrollRow {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    height: 110px;
  }

  .profile_services_box_scrollRow_element {
    height: 100px;
    width: 150px;
    padding:8px;
    border:1px solid #dddddd;
    border-radius:12px;
  }
}

@media only screen and (min-width: 1050px) {
  .profile {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);

    padding-top: 174px;
  }
}
